/*global prodcat*/
(function(site, $) {
  var formCaptureObj = {}, linkCaptureObj = {};
  var newUserSelectors = [], returnUserSelectors = [], paymentMethodSelectors = [], loyaltyEventSelectors = [];
  var drupalAltImgSettings = Drupal.settings.analytics ? Drupal.settings.analytics.alt_image_tagging_enabled : false;
  // Flag to avoid alt image tagging on select shade
  var shadeClickCheck = false;
  // Flag to avoid alt image tagging on changing shades dropdown
  var shadeChangeCheck = false;
  var previousBgFullImageUrl = null;

  Drupal.behaviors.analyticsBehavior = {
    attached: 0,

    findElementIndex: function(arr, value) {
      return _.findIndex(arr, function(elem) {
        return elem === value;
      });
    },

    linkToPage: function() {
      window.open(linkCaptureObj.href, linkCaptureObj.target);
    },

    setLinkCapture: function(href, target) {
      linkCaptureObj.href = href;
      linkCaptureObj.target = target;
    },

    submitForm: function() {
      formCaptureObj.form.off('submit');
      formCaptureObj.form.trigger('submit');
    },

    stripOutMarkup: function(str) {
      return str.replace(/(<([^>]+)>)/ig, '');
    },

    // Accepts an array of PRODUCT_IDS, returns an array of positions
    getProductPositions: function(productIds) {
      var positions = [0];
      var self = this;
      if (prodcat && prodcat.data && prodcat.data.pids) {
        positions = productIds.map(function(element) {
          return self.findElementIndex(prodcat.data.pids, element);
        });
      }
      return positions;
    },

    // Examples of brand specific overrides for event handling

    addToCart: function(eventData) {
      site.track.addToCart(Object.assign({}, eventData));
    },

    addToFavorites: function(eventData) {
      site.track.addToFavorites(Object.assign({}, eventData));
    },

    removeFromCart: function(eventData) {
      site.track.removeFromCart(Object.assign({}, eventData));
    },

    // End examples brand specific overrides for event handling

    attach: function(context) {
      // all code here
      var self = this;
      var drupalAnalyticObj = Drupal.settings.analytics;
      var postSampleSelection = drupalAnalyticObj ? drupalAnalyticObj.post_sample_selection : false;
      var analyticsSettings = Drupal.settings.analytics ? Drupal.settings.analytics.analytics_tagging_enabled : false;
      var eventObj = {};

      if (self.attached) {
        return;
      }

      // Event track common function call start
      function trackEvent(eName, eCategory, eAction, elabel) {
        Object.assign(eventObj, {
          event_name: eName,
          event_category: eCategory,
          event_action: eAction,
          event_label: elabel
        });
        site.track.evtLink(eventObj);
        eventObj = {};
      }
      // Event track common function call end.

      // Track Brand Logo
      $('a.site-logo__icon, a.site-utils__logo-icon', context).on('click', function(event) {
        var obj;
        event.preventDefault();
        self.setLinkCapture($(this).attr('href'), '_self');
        obj = {
          event_name: 'logo_click',
          event_category: 'global',
          event_action: 'logo clicked'
        };
        site.track.evtLink(obj, self.linkToPage);
      });

      //  Track Notify me button
      $('li.js-notify-me-alert').on('click', function() {
        var $currentElem = $(this);
        var $targetElem = $currentElem.closest('.js-product-full').length > 0 ? $currentElem.closest('.js-product-full') : $currentElem.closest('.js-product');
        var prodId = $targetElem.data('product-id');
        var prodName = $targetElem.find('h1.product-full__title:first').text().trim() || $targetElem.find('h3.product-brief__header').text().trim();

        site.track.evtLink({
          event_name: 'notify_me',
          event_action: 'notify me - request',
          event_category: 'ecommerce',
          event_label: prodName + ' - ' + prodId
        });
      });

      // Track Product Click.
      var productClickSelectors = [
        '.product-thumb a',
        '.product__full-details-link',
        '.product_quickshop__header',
        '.js-quickshop-smoosh',
        '.product-brief .product-brief__image-wrapper',
        '.product-brief .product-brief__headline',
        '.js-product-brief-image-slide.lazyloaded'
      ];
      window.selectChecker = function selectChecker(key){
        var count = 0;
        for(var i = 0; i < productClickSelectors.length; i++){
          if($(key).find(productClickSelectors[i]).length == 0){
            count++;
          }
        }
        if(count == productClickSelectors.length){
          return true;
        }
        else{
         return false;
        }
      }
      $(document).on('click', productClickSelectors.join(', '), function(event) {
        var $targetElem = $(this);

        if(window.selectChecker(this)){
          var prodElem = $(this).closest('.js-access-content, .js-product');
          var prodId = prodElem.attr('data-product-id');
          var targetClassObj = event.target.classList;
          var targetClassArr = Object.values(targetClassObj);
          if (typeof prodId !== 'undefined' && prodId !== '' && (targetClassArr.indexOf('js-add-to-cart') <= -1)) {
            site.track.productClick({
              targetElem: $targetElem,
              product_id: [prodId]
            });
          }
        }
      });

      // Product click on Recommendation section
      recomProductSelectors = [
        '.recommended-item a.thumb',
        '.recommended-item .product_name a',
        '.recommended-products-panel__content .recommended-item a.product_image',
        '.recommended-products-panel__content .product_name a',
        '.recommended-item .product_short_desc a',
        '.recommended-item .quickshop a',
        '.recommended-item a.quickshop',
        '#recommended-products-panel .recommended-item .addtobag a'
      ];
      $(document).on('click', recomProductSelectors.join(', '), function () {
        var $prodElem = $(this);
        var prodId = $prodElem.closest('.recommended-item').find('.recommended-item__button').data('product-id');
        if (typeof prodId == 'undefined') {
          var $prodHref = $prodElem.attr('href');
          var splitprod = $prodHref.split('/');
          var prodId = 'PROD' + splitprod[3];
        }
        if (typeof prodId !== 'undefined' && prodId !== '') {
          site.track.productClick({
            targetElem: $prodElem,
            product_id: [prodId]
          });
        }
      });

      // Track Quick Shop
      $(document).on('click', '.product-thumb__abstract .js-quickshop-link, .product-image-qs .js-quickshop-link, .product-thumb__abstract .js-quickshop-link', function() {
        var obj;
        var $targetelem = $(this);
        var catName = $('.content > article', context).attr('trackname');
        var prodId = $targetelem.closest('.js-quickshop-link').attr('data-product-id');
        var prodName = $targetelem.closest('.js-product').find('h3.product_brief__header, .product-image-qs__product-name, h3.product-thumb__headline a').text().trim();
        if (!prodName) {
          $targetelem.closest('.product-grid__item').find('a.product-thumb__headline-link').html();
        }
        var prodPrice = $targetelem.closest('.js-product').find('span.price').text().replace('$', ' ').trim();
        obj = {
          event_label: prodName + ' - ' + $targetelem.attr('data-product-id'),
          page_name: 'QV | ' + prodName,
          product_id: [prodId],
          product_catagory_name: [catName],
          product_price: [prodPrice]
        };
        //product click added here to ensure fires with Quick Shop
        site.track.productClick({
          targetElem: $targetelem,
          product_id: [prodId]
        });
        site.track.quickView(obj);
      });

      //Tracking Quick Shop for Explore Button
      $('.product-cta-qs__shop-details', context).on('click', function() {
        var obj;
        var $targetelem = $(this);
        var catName = $('.content > article', context).attr('trackname');
        var prodId = $targetelem.closest('.js-quickshop-link').attr('data-product-id');
        var prodPriceElem = $('.product-collection-product').find('span.product-collection-product__price').text().trim();
        var prodNamElem = $('.product-collection-product').find('h2.product-collection-product__product-name').text().replace(prodPriceElem, ' ').trim();
        var prodName = prodNamElem;
        var prodPrice = prodPriceElem;
        obj = {
          event_label: prodName + ' - ' + $targetelem.attr('data-product-id'),
          page_name: 'QV | ' + prodName,
          product_id: [prodId],
          product_catagory_name: [catName],
          product_price: [prodPrice]
        };
        site.track.quickView(obj);
      });

      //Track Select Shade for Mobile
      $('.show_select', context).on('click', function() {
        var obj;
        var $targetelem = $(this);
        var prodId = $targetelem.attr('data-product-id');
        var $productelem = $targetelem.closest('.js-product');
        var prodName = $productelem.find('h2.product_brief__header').text().trim();
        var prodPrice = $productelem.find('span.price').text().trim();
        obj = {
          event_label: prodName + ' - ' + prodId,
          page_name: 'QV | ' + prodName,
          product_id: [prodId],
          product_price: [prodPrice]
        };
        site.track.quickView(obj);
      });

      // Track Predictive Search Product Click and All Results Click
      $(document).on('click', '.js-typeahead-gap-tag .js-analytics-typeahead-product-result', function() {
        var obj;
        var $typeaheadelem = $(this);
        var term = $typeaheadelem.parent().closest('#perlgem-search-form').find('.search-term').val();
        var attributes = $typeaheadelem.find('a.link, a');
        var prodId = $typeaheadelem.find('.js-product-image-wrapper, .product a, a').attr('data-product-id');
        self.setLinkCapture($(attributes).attr('href'), '_self');
        obj = {
          event_label: term,
          search_keyword: term,
          product_id: prodId,
          product_name: $typeaheadelem.find('.product_result_header').text()
        };
        site.track.evtAction('searchOneResultSelected', obj, self.linkToPage);
      });

      // Track MPP Filters
      $(document).on('click', 'a.js-filter-trigger, .js-mpp-sort-option', function () {
        var $elem = $(this);
        var obj = {
          event_label: $elem.text().trim()
        };
        site.track.evtAction('filterProducts', obj);
      });

      // CHECKOUT EVENTS

      // Track guest user checkout
      newUserSelectors = [
        '#checkout_signin_guest_user-submit',
        '#checkout_signin_new_user-submit',
        '.sign-in-page.account__section.checkout .js_analytics_checkout_new_user',
        '.js_analytics_mobile_new_user',
        '#checkout_signin_new_user .new-account__submit',
        '.new-account__submit'
      ];
      $(document).on('click', newUserSelectors.join(', '), function() {
        var obj = {};
        site.track.evtAction('checkoutGuestUser', obj);
      });

      // Track return user checkout
      returnUserSelectors = [
        '#checkout_signin-submit',
        '.sign-in-page.account__section.checkout .js_analytics_checkout_return_user',
        '#checkout_signin .return-user__submit'
      ];
      $(document).on('click', returnUserSelectors.join(', '), function() {
        var obj = {};
        site.track.evtAction('checkoutReturnUser', obj);
      });

      loyaltyEventSelectors = [
        '.js-account-page__non__loyalty__join__now__link',
        '.js-sign-in-page__submit',
        '.js-loyalty-join-now-submit',
        '.js-loyalty__section__content__options__join__link'
      ];
      $(document).on('click', loyaltyEventSelectors.join(', '), function() {
        var pageID = window.utag_data ? window.utag_data.page_name : ' ';
        site.track.loyaltyTag(pageID);
      });
      paymentMethodSelectors = [
        '#checkout_billing input.js-submit-payment',
        '.js_analytics_checkout_payment',
        '.js_analytics_mobile_payment',
        '#checkout_shipping input.form-submit',
        '#checkout_review .js-analytics-review-submit',
        '.js-analytics-payment-click',
        '.js_analytics_payment_click',
        '.js-continue-payment',
        '.js_analytics_payment'
      ];
      $(document).on('click', paymentMethodSelectors.join(', '), function() {
        var paymentLabel = '';
        var paymentTypePP = [
          'PayPal',
          'PP',
          'PAYMENT_OPTION_PAYPAL'
        ];
        var paymentTypeCC = [
          'Credit Card',
          'CC',
          'PAYMENT_OPTION_MC',
          'PAYMENT_OPTION_CC',
          'PAYMENT_OPTION_VISA',
          'Pelecard',
          'GMO',
          'SecurePay',
          'Pelecard',
          'SecurePay',
          'Pelecard',
          'KBank',
          'GMO',
          'PP_MPCC'
        ];
        var paymentTypeCOD = [
          'PAYMENT_OPTION_COD',
          'COD',
          'PP_CASH'
        ];
        var paymentTypeBT = [
          'BT'
        ];
        var paymentTypeAfterpay = [
          'AfterPay'
        ];
        var paymentType = $('input[name=PP_NAME]:checked').val() || $('input[name=PAYMENT_METHOD]:checked').val() || $('input[name=PAYMENT_TYPE]:checked').val() || $('input[name=PAYMENT_OPTION]:checked').val();
        if ($.inArray(paymentType, paymentTypePP) > -1) {
          paymentLabel = 'paypal';
        } else if ($.inArray(paymentType, paymentTypeCC) > -1) {
          paymentLabel = 'creditcard';
        } else if ($.inArray(paymentType, paymentTypeCOD) > -1) {
          paymentLabel = 'cashondelivery';
        } else if ($.inArray(paymentType, paymentTypeBT) > -1) {
          paymentLabel = 'banktransfer';
        } else if ($.inArray(paymentType, paymentTypeAfterpay) > -1) {
          paymentLabel = 'afterpay';
        }
        var obj = {
          event_label: paymentLabel
        };
        if (paymentLabel.length !== 0) {
          site.track.evtAction('checkoutPaymentSelected', obj);
        }
      });

      // Track Paypal Express Check-out
      $('a.paypal-checkout').on('click', function() {
        var obj = {
          event_label: 'paypal'
        };
        site.track.evtAction('checkoutPaymentSelected', obj);
      });

      // mic button to search tagging
      $('.js-mic-event').on('click', function() {
        var obj = {
          'event_category': 'onsite search voice',
          'event_action': 'search mic click',
          'event_label': 'voice search'
        };
        site.track.evtLink(obj);
      });

      // END CHECKOUT EVENTS
      
      //BB foundation finder Tagging start
      if ($('body').hasClass('section-foundation-finder')) {
        trackFoundationTagging();
      }

      function trackFoundationTagging() {
        var obj = {};
        var stepChecker;
        initFoundationTagging();

        function trackFoundationStart() {
          $(document).on('click', '[data-field-name ="intro"] .js-mantle-cta-button', function() {
            trackFoundationEvent('diagtools', 'start button', 'click');
            trackFoundationPage(location.pathname + '/step1', '/foundation-finder/step1', 'Foundation-Finder - step1');
          });
        }

        function trackFoundationSteps() {
          window.foundationAnswer = '';
          var selectorElement = [
            {selector: '[data-field-name="intensity"]', name: 'foundationfinder_step1', step: 'step2', action: 'questions'},
            {selector: '[data-field-name="undertone"]', name: 'foundationfinder_step2', step: 'step3', action: 'questions'},
            {selector: '[data-field-name="shade"]', name: 'foundationfinder_step3', step: 'step4', action: 'questions'},
            {selector: '[data-field-name="formula"]', name: 'foundationfinder_step4', step: 'step5', action: 'questions'}
          ];
          selectorElement.forEach(function(elem) {
            $(document).on('click', elem.selector + ' .js-mantle-cta-button', function() {
              if (!$(this).hasClass('button--disabled')) {
                var temp = $(this).closest('.form-formatter__item').attr('data-field-name');
                if (window.foundationAnswer !== temp) {
                  window.foundationAnswer = temp;
                  var $foundationElement = $(this);
                  var userSelection;
                  var $foundationBlockElement = $foundationElement.closest('.form-formatter__item');
                  var userIntensitySelection = $foundationBlockElement.find('.js-range-slider-input').attr('data-form-value');
                  var userUndertoneSelection = $foundationBlockElement.find('.js-mantle-form__checkbox.active .js-adpl-active').val();
                  var userShadeSelection = 'Shade' + ' - ' + ($foundationBlockElement.find('.js-basic-carousel__slide.slick-active .range-slider-item__label:first, .js-basic-carousel__slide.slide-selected .range-slider-item__label:first').text().trim() || $foundationBlockElement.find('.js-basic-carousel__slide.slide-selected-finished .range-slider-item__label:first').text().trim() || $foundationBlockElement.find('.js-basic-carousel__slide .range-slider-item__label:first').text().trim());
                  var userFormulaId = $foundationBlockElement.find('.js-mantle-form__checkbox.active').data('field-value');
                  var userFormulaName = $foundationBlockElement.find('.js-mantle-form__checkbox.active .product-brief__top-shadename').text().trim() || $foundationBlockElement.find('.js-mantle-form__checkbox.active .label-content').text().trim();
                  var userFormulaSelection = userFormulaId + ' - ' + userFormulaName;
                  stepChecker = elem.step;
                  if (userIntensitySelection || userUndertoneSelection || userShadeSelection || userFormulaSelection) {
                    userSelection = stepChecker === 'step2' ? userIntensitySelection.replace('_', '-') : stepChecker === 'step3' ? userUndertoneSelection.replace('_', '-') : stepChecker === 'step4' ? userShadeSelection : userFormulaSelection.replace('_', '-');
                  }
                  if (typeof userSelection !== 'undefined' && userSelection !== undefined && userSelection) {
                    trackFoundationEvent(elem.name, elem.action, userSelection);
                  }
                  if (typeof userSelection !== 'undefined' && userSelection !== undefined && $foundationElement.attr('data-trigger-type') !== 'submit') {
                    trackFoundationPage('/foundation-finder/' + elem.step, '/foundation-finder/' + elem.step, 'Foundation-Finder - ' + elem.step);
                  }
                }
              }
            });
          });
        }

        function trackFoundationOverlayOpen() {
          $(document).on('click', '#cboxWrapper a.js-mantle-cta-button', function() {
            var overlayHeader = $('.js-form-formatter__item.active').attr('data-field-name');
            if (overlayHeader === 'undertone') {
              trackFoundationEvent('diagtools', 'overlay', 'displayed - Undertone');
            }
          });
        }

        function trackFoundationOverlayClose() {
          $(document).on('click', '#cboxWrapper #cboxClose', function() {
            var overlayHeader = $('.js-form-formatter__item.active').attr('data-field-name');
            if (overlayHeader === 'undertone') {
              trackFoundationEvent('diagtools', 'overlay', 'closed - Undertone');
            }
          });
        }

        function trackFoundationComplete() {
          window.foundationResult = true;
          $(document).on('FoundationFinderResults', function() {
            if (window.foundationResult) {
              var $menuItems = $('.foundation-finder-result-page__item.active .foundation-finder-result__foundation');
              var $recommendedItems = $('.foundation-finder-result-page__item.active .js-foundation-finder-result__section');
              var productImpressionIds = [];
              var productPositions = [];
              var productImpressionList = [];
              var $elem;
              $menuItems.find('.js-product').each(function(index, elem) {
                if (!$(elem).length) {
                  return;
                }
                $elem = $(elem);
                productImpressionIds.push($elem.attr('data-product-id'));
                productPositions.push(index + 1);
                productImpressionList.push(location.pathname + '/results');
                $(this).attr('menu-order', index + 1);
              });

              $recommendedItems.find('.js-product').each(function(index, elem) {
                if (!$(elem).length) {
                  return;
                }
                $elem = $(elem);
                productImpressionIds.push($elem.attr('data-product-id'));
                productPositions.push(index + 1);
                productImpressionList.push(location.pathname + '/also_recommended');
                $(this).attr('menu-order', index + 1);
              });

              var objView = {
                product_impression_list: productImpressionList,
                product_impression_position: productPositions,
                product_impression_id: productImpressionIds,
                location: location.origin + location.pathname + '/results',
                page_url: '/foundation-finder/results',
                page_name: 'Foundation-Finder - Results'
              };
              site.track.evtView(objView);
              window.foundationResult = false;
            }
          });
        }

        function trackProductResult() {
          $(document).on('click', 'a.js-add-to-cart', function() {
            var prodElem = $(this).closest('.ff-product-card.js-product');
            var productId = prodElem.attr('data-product-id');
            if ($(this).hasClass('js-add-to-cart')) {
              trackAddtoBagPage(productId);
            }
          });
        }

        function retakeQuiz() {
          $(document).on('click', '.js-mantle-form-reset.retake-quiz', function() {
            window.foundationResult = true;
            trackEvent('diagtools', 'diagnostic tool - Foundation Finder', 'Star Over', 'click');
            trackFoundationPage('/foundation-finder', '/foundation-finder', 'MPP | Foundation-Finder - Page');
          });
        }

        function trackBackButton() {
          $(document).on('click', 'a.js-back-link', function() {
            window.foundationAnswer = '';
            var backStep = $(this).closest('.foundation-finder-quiz').attr('data-current-step');
            trackEvent('diagtools', 'diagnostic tool - Foundation Finder', 'back button', 'click');
            if (backStep !== '0') {
              trackFoundationPage('/foundation-finder/step' + backStep, '/foundation-finder/step' + backStep, 'Foundation-Finder - Step' + backStep);
            } else {
              trackFoundationPage('/foundation-finder', '/foundation-finder', 'MPP | Foundation-Finder - Page');
            }
          });
        }

        function trackFoundationEvent(name, action, label) {
          Object.assign(obj, {
            event_name: name,
            event_category: 'diagnostic tool - Foundation Finder',
            event_action: action,
            event_label: label
          });
          site.track.evtLink(obj);
          obj = {};
        }

        function trackFoundationPage(pUrl, plocation, pName) {
          var objView = {
            page_url: pUrl,
            location: location.origin + plocation,
            page_name: pName
          };
          site.track.evtView(objView);
        }

        function trackAddtoBagPage(productId) {
          var objView = {
            enh_action: 'detail',
            product_id: [productId]
          };
          site.track.evtView(objView);
        }

        function initFoundationTagging() {
          trackFoundationStart();
          trackProductResult();
          trackFoundationSteps();
          trackAddtoBagPage();
          trackFoundationComplete();
          trackFoundationOverlayOpen();
          trackFoundationOverlayClose();
          trackBackButton();
          retakeQuiz();
        }
      }
      //BB foundation finder Tagging end

      //BB Shade Module tagging
      if ($('body').find('.js-shade-finder-block') && analyticsSettings) {
        trackShadeModule();
      }

      function trackShadeModule() {
        var userSelection;
        var slideCompare;
        initShadeModule();

        function trackShadeModuleFilter() {
          $('.js-shade-finder-block-filter', context).once('js-shde-filter').each(function() {
            var $shadeFilter = $(this);
            $shadeFilter.on('click', function() {
              userSelection = $(this).text().trim();
              trackShadeModuleEvent('Shade Finder Grid Filter', 'Find Your Match', 'filter - filter by shade', userSelection);
            });
          });
        }

        function trackShadeModuleProductBefore() {
          $('.js-range-slider-carousel__carousel', context).on('beforeChange', function(event, slick, currentSlide, targetSlide) {
            slideCompare = currentSlide;
          });
        }

        function trackShadeModuleProductAfter() {
          $('.js-range-slider-carousel__carousel').on('afterChange', function(event, slick, currentSlide) {
            if (typeof slideCompare !== 'undefined' && slideCompare !== undefined && slideCompare !== currentSlide) {
              var $shadeProductMobile = slick.$slides[currentSlide];
              var productSelection = $($shadeProductMobile).find('.range-slider-item__label').text().trim();
              trackShadeModuleEvent('Shade Finder Grid Product', 'Find Your Match', 'open overlay', productSelection);
            }
          });
        }

        function trackShadeModuleEvent(name, category, action, label) {
          var obj = {
            event_name: name,
            event_category: category,
            event_action: action,
            event_label: label
          };
          site.track.evtLink(obj);
        }

        function initShadeModule() {
          trackShadeModuleFilter();
          trackShadeModuleProductBefore();
          trackShadeModuleProductAfter();
        }
      }
      //BB Shade Module tagging end

      //Home page Tout tagging start
      if ($('div').hasClass('basic-formatter') && analyticsSettings) {
        trackToutTagging();
      }

      function trackToutTagging() {
        $('.js-tout-base.content-formatter__grid-small .content-formatter__rendered', context).once('js-promo-click').each(function() {
          var $promoElem = $(this);
          $promoElem.on('click', function() {
            var promoName = $promoElem.find('.mantle-custom-text p').text().trim();
            var promoId = 'banner - 4 Grid Tout - ' + promoName;
            eventObj.enh_action = 'promo_click';
            eventObj.promo_creative = ['4 Grid Tout'];
            eventObj.promo_id = [promoId];
            eventObj.promo_name = [promoName];
            eventObj.promo_pos = ['banner'];
            trackEvent('navigation_click tout', 'ecommerce', 'promotion click', 'Banner Click');
          });
        });
      }
      //Home page Tout tagging end

      //Home page Hero banner Tagging start
      if ($('div').hasClass('basic-carousel__container') && analyticsSettings) {
        trackHeroBannerTagging();
      }

      function trackHeroBannerTagging() {
        $('.js-basic-carousel__slide .js-content-block-link', context).once('js-banner-click').each(function() {
          var $bannerElem = $(this);
          $bannerElem.on('click', function() {
            var bannerLabel = $bannerElem.text().trim();
            var $bannerParentElem = $bannerElem.closest('.content-block__content-wrapper');
            var bannerHeader = $bannerParentElem.find('.content-block__line--header .mantle-custom-text').text().trim();
            var bannerHrefAttr = $bannerElem.attr('href');
            var promoName = bannerHeader ? bannerHeader : bannerHrefAttr;
            var promoId = 'banner - Main Hero - ' + promoName;
            eventObj.enh_action = 'promo_click';
            eventObj.promo_creative = ['Main  Hero'];
            eventObj.promo_id = [promoId];
            eventObj.promo_name = [promoName];
            eventObj.promo_pos = ['banner'];
            trackEvent('navigation_click Hero', 'ecommerce', 'promotion click', 'Banner Click - ' + bannerLabel);
          });
        });
      }
      //Home page Hero banner Tagging end

      //Split Width Analytic tagging start
      if ($('div').hasClass('split-width-content-block') && analyticsSettings) {
        trackSplitWidthTagging();
      }

      function trackSplitWidthTagging() {
        $('.split-width-content-block .js-content-block-link', context).once('js-split_width-click').each(function() {
          var $splitElem = $(this);
          $splitElem.on('click', function() {
            var promoName = $splitElem.closest('.content-block__content-text').find('.content-block__line--header').text().trim();
            var promoId = 'banner - Spilt Width - ' + promoName;
            eventObj.enh_action = 'promo_click';
            eventObj.promo_creative = ['Spilt Width'];
            eventObj.promo_id = [promoId];
            eventObj.promo_name = [promoName];
            eventObj.promo_pos = ['banner'];
            trackEvent('navigation_click split width', 'ecommerce', 'promotion click', 'Banner Click - ' + $splitElem.text().trim());
          });
        });
      }
      //Split Width Analytic tagging end

      // Alt image tracking start
      window.onload = function() {
        if ($('body').hasClass('section-product') && drupalAltImgSettings) {
          // Disable alt image tagging while selecting shades
          $(document).on('click', '.js-product-full-shades-grid-item, .js-product-brief-shades-item-link', function() {
            shadeClickCheck = true;
            resetArrowClickEventActionFlags($(this));
          });
          $(document).on('change', '.js-shade-select', function() {
            shadeChangeCheck = true;
            resetArrowClickEventActionFlags($(this));
          });
          if ($('body').hasClass('device-pc')) {
            trackAltImageTrackingClickPC();
          } else if ($('body').hasClass('device-mobile')) {
            trackAltImageTrackingMobile();
          }
        }
      };

      // Track Alt image click - desktop
      function trackAltImageTrackingClickPC() {
        var altImageClickSelectors = [
          '.js-product-full-image-thumb',
          '.product-full-image__main-carousel-arrow',
          '.js-product-full-image-thumb-video'
        ];
        $(document).on('click', altImageClickSelectors.join(', '), function(event) {
          if (!event.isTrigger) {
            var $targetElem = $(this);
            var altImageName = '';
            var prodDetails;
            var bgImageUrl = '';
            var bgFullImageUrl = '';
            if ($targetElem.hasClass('product-full-image__main-carousel-arrow')) {
              var $prodElem = $targetElem.closest('.product-full-image__main-carousel');
              prodDetails = buildAltImageProductName($prodElem);
              bgFullImageUrl = $prodElem.siblings('.js-product-full-image-main').find('.active').attr('src');
            } else if ($targetElem.hasClass('js-product-full-image-thumb') && $('.js-product-full-image-thumb').length > 1) {
              prodDetails = buildAltImageProductName($targetElem);
              bgImageUrl = $targetElem.find('.product-full-image__photo--thumb').attr('src');
            } else if ($targetElem.hasClass('js-product-full-image-thumb-video') && $('.js-product-full-image-thumb-video').length > 1) {
              prodDetails = buildAltImageProductName($targetElem);
              bgImageUrl = $targetElem.find('.product-full-image__photo--thumb').attr('src');
            }
            if ($targetElem.hasClass('js-thumbs-left') && $targetElem.hasClass('inactive') && previousBgFullImageUrl === null) {
              previousBgFullImageUrl = bgFullImageUrl;
            }
            if (!!bgImageUrl) {
              altImageName = buildAltImageFileName(bgImageUrl);
              trackThumbnailClickEvent(altImageName, prodDetails[0], prodDetails[1], 'click');
            } else if (!!bgFullImageUrl && bgFullImageUrl !== previousBgFullImageUrl) {
              altImageName = buildAltImageFileName(bgFullImageUrl);
              previousBgFullImageUrl = bgFullImageUrl;
              trackThumbnailClickEvent(altImageName, prodDetails[0], prodDetails[1], 'click');
            }
          }
        });
      }

      // Track Alt image swipe - Mobile
      function trackAltImageTrackingMobile() {
        $(document).on('SLICKCHANGE', function() {
          if (!shadeClickCheck && !shadeChangeCheck) {
            var $targetElem = $(this).find('.js-product-full-image-main');
            var altImageName = '';
            var prodDetails = buildAltImageProductName($targetElem);
            var bgImageUrl = $targetElem.find('.slick-active').attr('src');
            if (bgImageUrl) {
              altImageName = buildAltImageFileName(bgImageUrl);
            }
            trackThumbnailClickEvent(altImageName, prodDetails[0], prodDetails[1], 'swipe');
          }
          shadeClickCheck = false;
          shadeChangeCheck = false;
        });
      }

      // Build Alt image product name
      function buildAltImageProductName(targetElem) {
        var prodElem = targetElem.closest('.js-product');
        var prodId = prodElem.attr('data-product-id');
        var prodName = prodElem.find('.product-full__title').text();
        if (prodName.length) {
          prodName = prodName.trim();
        }
        return [prodName, prodId];
      }

      // Build Alt image file name
      function buildAltImageFileName(altImageUrl) {
        var altImageArray = altImageUrl.split('/');
        var altImageName = altImageArray[altImageArray.length - 1].split('.')[0];
        return altImageName;
      }

      // Reset PC & Mobile site arrow click or swipe event action flags
      function resetArrowClickEventActionFlags(targetElem) {
        var bgImageArrowIndexCount = targetElem.closest('.product-full__data').siblings('.product-full__media').find('.js-product__altcount').text().split('/')[0];
        if (bgImageArrowIndexCount !== 2) {
          previousBgFullImageUrl = null;
        }
      }

      // Track product thumbnail click event
      function trackThumbnailClickEvent(altImageName, prodName, prodId, eventType) {
        var obj = {
          'event_action': 'alt image - ' + altImageName + ' - ' + eventType,
          'event_label': prodName + ' - ' + prodId
        };
        site.track.productThumbnailClick(obj);
      }
      // Alt image tracking end

      // Tab Mpp Filter Tagging start.
      if ($('div').hasClass('js-mpp-filter-analytics')) {
        trackTabFilterTagging();
      }

      function trackTabFilterTagging() {
        initTabFilterTagging();

        function trackMppFilter() {
          $(document).on('click', 'a.js-mpp-filter-set-submit', function () {
            var $multiFilterElem = $('.js-mpp-filter-set-button.active');
            var filterArray = [];

            $multiFilterElem.each(function () {
              filterArray.push($(this).text().trim());
            });

            var eventLabel = filterArray.join('|');

            if (eventLabel) {
              trackEvent('filters_and_sort_selection', 'filter & sort selection', 'filter - MPP', eventLabel);
            }
          });
        }

        function trackClearAll() {
          $(document).on('click', '.js-mpp-filter-set-reset', function () {
            var eventLabel = $(this).text().trim();

            trackEvent('filters_and_sort_selection', 'filter & sort selection', 'filter - MPP', eventLabel);
            $('.js-mpp-filter-set-button').removeClass('filterSelected');
          });
        }

        function trackFilterSelect() {
          $(document).on('click', '.js-mpp-filter-set-button.active', function () {
            $(this).addClass('filterSelected');
          });
        }

        function trackDeselect() {
          $(document).on('click', '.js-mpp-filter-set-button.filterSelected', function () {
            var eventAction = $(this).text().trim();

            trackEvent('filters_and_sort_selection', 'filter & sort selection', eventAction, 'X');
            $(this).removeClass('filterSelected');
          });
        }

        function trackMppSortBy() {
          $(document).on('change', '.js-mpp-sort-menu', function () {
            var eventLabel = $('.js-mpp-sort-menu option:selected').text().trim();

            trackEvent('filters_and_sort_selection', 'filter & sort selection', 'sort - MPP', eventLabel);
          });
        }

        function initTabFilterTagging() {
          trackMppFilter();
          trackClearAll();
          trackFilterSelect();
          trackDeselect();
          trackMppSortBy();
        }
      }
      // Tab Mpp Filter Tagging end.

      // How to navigation Tagging start.
      if ($('body').hasClass('section-how-to')) {
        $(document).on('click', '.js-content-filter-set-filter-button', function() {
          var eventLabel = $(this).text().trim();
          trackEvent('howto', 'how to', 'Filter Click', eventLabel);
        });
      }
      // How to navigation Tagging end.

      // Waitlist-signup Tagging start.
      if ($('body').hasClass('section-vitamin-enriched-eye-base-2020-waitlist-signup')) {
        $(document).on('Waitlist', function(event, data) {
          var waitlistStatus = data;
          trackEvent('login_success', 'sign up', 'waitlist', waitlistStatus);
        });
      }
      // Waitlist-signup Tagging end.

      // MTA-4093 - Advent Calendar Tagging Start
      if ($('body').hasClass('section-advent-calendar')) {
        trackAdventCalenderTagging();
      }

      function trackAdventCalenderTagging() {
        var doorNumber;

        initCalendarTagging();

        function windowClickTagging() {
          $(document).on('click', '.card', function () {
            var windowStatus = $(this).hasClass('active') ? 'Live' : 'Not Live';

            doorNumber = $(this).data('position');
            var eventLabel = doorNumber + ' - ' + windowStatus;

            trackEvent('adventcalendar', 'advent calendar', 'window click', eventLabel);
          });
        }

        function redeemClickTagging() {
          $(document).on('click', '.js-apply', function () {
            trackEvent('adventcalendar', 'advent calendar', 'redeem', doorNumber);
          });
        }

        function removeClickTagging() {
          $(document).on('click', '.js-applied', function () {
            trackEvent('adventcalendar', 'advent calendar', 'remove', doorNumber);
          });
        }

        function initCalendarTagging() {
          windowClickTagging();
          redeemClickTagging();
          removeClickTagging();
        }
      }
      // MTA-4093 - Advent Calendar Tagging end

      // MTA-3556 - One click checkout tagging start.
      if ($('div').hasClass('js-one-click-btn')) {
        trackOneClickTagging();
      }

      function trackOneClickTagging() {
        initOneClickTagging();

        function trackBuyNow() {
          $(document).on('click', '.js-buy-now-btn, .place-order-btn-wrapper', function () {
            var eventAction = $(this).text().trim();
            trackEvent('oneclickcheckout', 'one click checkout', eventAction, 'click');
            if ($(this).hasClass('js-buy-now-btn')) {
              if (site && site.track) {
                site.track.oneClickcheckout();
              }
            }
          });
        }

        function trackOverlayClose() {
          $(document).on('click', '#cboxOverlay, #cboxClose', function () {
            trackEvent('oneclickcheckout', 'one click checkout', 'close', 'click');
          });
        }

        function initOneClickTagging() {
          trackBuyNow();
          trackOverlayClose();
        }
      }
      // One click checkout tagging end.

      // Bobbi onepage foundation finder tagging start.
      if ($('div').hasClass('js-quiz-landing-page-quiz-form')) {
        trackOnePageFFTagging();
      }

      function trackOnePageFFTagging() {
        initOnePageFFTagging();

        function trackFFsteps() {
          $(document).on('click', '.mantle-form-options-field__item', function () {
            var filterLabel = $(this).closest('.js-form-formatter__item').find('.mantle-custom-text').first().text().trim();
            var filterSelected = $(this).find('.label-content').text().trim();
            var stepCount = $(this).closest('.js-form-formatter__item').find('.js-current-step').text().trim();
            var totalStep = $(this).closest('.js-form-formatter__item').find('.js-total-step').text().trim();

            eventObj.page_url = location.pathname + '/step_' + stepCount;
            eventObj.page_name = 'Foundation-Finder - Step' + stepCount;
            if (filterSelected) {
              trackEvent('diagtools', 'diagnostic tool - Foundation Finder', 'questions', filterLabel + ' - ' + filterSelected);
            }
            if (stepCount < totalStep) {
              trackFFPageView(parseInt(stepCount) + 1);
            }
          });
        }

        function trackFFPageView(ffStep) {
          var objView = {
            page_url: location.pathname + '/step_' + ffStep,
            location: location.origin + location.pathname + '/step_' + ffStep,
            page_name: 'Foundation-Finder - Step' + ffStep
          };

          site.track.evtView(objView);
        }

        function initOnePageFFTagging() {
          trackFFsteps();
        }
      }
      // Bobbi onepage foundation finder taggingstart end.

      // BB Loyalty sign-up tagging start.
      if ($('section').hasClass('js-loyalty-popup')) {
        $(document).on('loyalSignup', function(event) {
          trackEvent('loyalty', 'loyalty', 'pop up', 'click');
        });
      }
      // BB Loyalty sign-up tagging end.

      // MTA-4509 Start of Flip Card Game Analytics Tagging.
      function gameStart() {
        if ($('body').hasClass('section-match-to-win')) {
          trackEvent('site_game', 'site game', 'start game', 'click');
        }
      }

      window.onload = function () {
        gameStart();
      };

      if ($('body').hasClass('section-match-to-win')) {
        var selectorArray = [
          'js-try-again',
          'js-start-over'
        ];

        $.each(selectorArray, function (i, selector) {
          $('.' + selector).on('click', function (e) {
            var attemptsLeft = 3 - ($('span').closest('.js-counts-left').text());
            var eventAction = (selector === 'js-start-over') ? 'no win - game ' + attemptsLeft + ' of 3' : 'play - game ' + attemptsLeft + ' of 3';
            var eventLabel = (selector === 'js-start-over') ? 'displayed' : 'play again';

            trackEvent('site_game', 'site game', eventAction, eventLabel);
          });
        });
      }

      //  When customer starts the game
      $(document).on('click', '.digital-game-start__start-btn', function () {
        var gameName = $('body').find('section-match-to-win') ? 'flip' : 'spin';

        trackEvent('site_game', 'site game', 'play', gameName);
      });

      // When customer redeem/shop
      $(document).on('click', '.js-redeem-offer', function () {
        var flipPromoName = $('div').closest('.js-selected-offer-message').text().trim();
        var flipPromoType = flipPromoName.includes('off') ? 'offer' : 'product';

        eventObj.enh_action = 'promo_click';
        eventObj.promo_pos = ['module'];
        eventObj.promo_name = [flipPromoName];
        eventObj.promo_destination = [flipPromoType];
        eventObj.promo_id = ['module - ' + flipPromoName];

        trackEvent('site_game', 'ecommerce', 'promotion click', 'banner click | ' + flipPromoName);
      });

      // When customer wins the game.
      $(document).on('eventMatchWin', function () {
        var gamePromoName = $('div').closest('.js-selected-offer-message').text().trim();
        var gameAttempt = 3 - $('span').closest('.js-balance-try-after-match').text().trim();
        var gamePromoType = gamePromoName.includes('off') ? 'offer' : 'product';

        if (gamePromoType === 'offer') {
          eventObj.promo_pos = ['module'];
          eventObj.promo_name = [gamePromoName];
          eventObj.promo_id = ['module - ' + gamePromoName];

          trackEvent('site_game', 'site game', 'win - ' + gamePromoType, 'attempt ' + gameAttempt + ' of 3');
        } else {
          trackEvent('site_game', 'site game', 'win - ' + gamePromoType, 'attempt ' + gameAttempt + ' of 3');
        }
      });
      // MTA-4509 End of Flip Card Game Analytics Taggging.

      // Module open close clicks
      $(document).on('mousedown touchdown', '.js-toggle-link-title, .js-samples-heading, .samples__header-text', function () {
        var $currentElem = $(this);
        var $samplePanel = $currentElem.closest('#samples-panel');
        var evtLabel = $currentElem.text().trim();
        var clickSense = false;
        var $toggleLinkItemElem = $samplePanel.find('.js-toggle-link-item');
        var $samplesContentElem = $samplePanel.find('.js-samples-content');

        if ($toggleLinkItemElem.length === 1) {
          clickSense = $toggleLinkItemElem.is(':visible');
        } else if ($samplesContentElem.length === 1) {
          clickSense = $samplesContentElem.is(':visible');
        } else {
          clickSense = $samplePanel.find('.samples__content').is(':visible');
        }

        if (clickSense) {
          trackEvent('sample_module_close', 'samples', 'module_close', evtLabel);
        } else {
          trackEvent('sample_module_open', 'samples', 'module_open', evtLabel);
        }
      });

      // Tracking arrow clicks
      $(document).on('mousedown touchdown', '.slick-prev, .slick-next, .samples__previous, .samples__next', function () {
        var $currentElem = $(this);
        var $samplePanel = $currentElem.closest('#samples-panel');
        var carouselName = '';
        var $toggleLinkTitleElem = $samplePanel.find('.js-toggle-link-title');
        var $samplesHeadingElem = $samplePanel.find('.js-samples-heading');

        if ($toggleLinkTitleElem.length === 1) {
          carouselName = $toggleLinkTitleElem.text().trim();
        } else if ($samplesHeadingElem.length === 1) {
          carouselName = $samplesHeadingElem.text().trim();
        } else {
          carouselName = $samplePanel.find('.samples__header-text').text().trim();
        }

        if (!($currentElem.hasClass('disabled') || $currentElem.hasClass('slick-disabled')) && carouselName) {
          trackEvent('sample_arrow_click', 'samples', 'arrow_click', carouselName);
        }
      });

      // Sample Add Remove tracking
      $(document).on('click', '.sample-select-button, .js-sample-select-button', function () {
        var $currentElem = $(this);
        var $prodContainerElem = $currentElem.closest('.details, .sample__description, .product');
        var $targetElem = $prodContainerElem.length > 0 ? $prodContainerElem.first() : $currentElem;
        var $sampleSelectElem = $targetElem.find('input.sample-select, input.js-sample-select-option, input.sample-input');
        var $skuSelected = $sampleSelectElem.length > 0 ? $sampleSelectElem.first() : '';
        var skuId = $skuSelected.length === 1 ? $skuSelected.val() : '';
        var $productNameElem = $targetElem.find('.product-name, .sample-name, .product_name');
        var skuName = $productNameElem.length > 0 ? $productNameElem.first().text().trim() : '';
        var $samplePageElem = $('body').attr('id') || $('body').attr('class');
        var separateSamplePage = (($samplePageElem && $samplePageElem.indexOf('sample') > -1) || $('section.samples-page').length === 1 || $('section.samples-panel').length === 1) ? true : false;
        var removeEvent = false;

        removeEvent = $currentElem.hasClass('sample-select-button') ? !$skuSelected.prop('checked') : $currentElem.hasClass('selected');

        if (postSampleSelection) {
          removeEvent = !removeEvent;
        }

        skuId = (skuId && skuId.toUpperCase().includes('SKU')) ? skuId : 'SKU' + skuId;
        if (removeEvent) {
          trackEvent('samples', 'samples', 'samples_remove', skuName + ' - ' + skuId);
        } else if (separateSamplePage) {
          trackEvent('samples', 'samples', 'samples_added', skuName + ' - ' + skuId);
        }
      });

      // MTA-6537 Jumplinks - MPP - Analytics tagging - Start
      $('.js-products-sticky-nav__links .products-sticky-nav__link a').on('click', function () {
        trackEvent('jumplink_click', 'plp', 'click', $(this).text());
      });

      $(document).on('click', '.js-supercat-header .js-content-block-link', function () {
        var $contentElem = $(this).closest('.supercat_product_layout__header-content');
        var $moduleNameElem = $contentElem.find('.js-analytics-content-txt .content-block__line--header');
        var moduleName = $moduleNameElem.length > 0 ? $moduleNameElem.text() : '';
        var clickedLink = $(this).text();

        site.track.evtLink({
          event_name: 'content_module_click',
          enh_action: 'promo_click',
          event_category: 'plp',
          event_action: 'promotion click',
          event_label: 'content link click',
          promo_pos: ['module'],
          promo_creative: [moduleName],
          promo_name: [clickedLink],
          promo_id: ['module - ' + moduleName + ' - ' + clickedLink],
          promo_destination: [$(this).attr('href')]
        });
      });
      // MTA-6537 Jumplinks - MPP - Analytics tagging - End

      self.attached = 1;
    }
  };
}(window.site || {}, jQuery));
